<template lang="pug">
app-layout
  v-app.layout-full
    .page-oauth-authorization
      .page-oauth-authorization__toolbar
        portal-da-logo.page-oauth-authorization-content__logo(width=180)
      .page-oauth-authorization__content
        .page-oauth-authorization-content
          .page-oauth-authorization-content__figure
            titled-image(
              image="auth-image"
              :title="!error ? 'Вход в систему' : 'Ошибка авторизации'"
            )
          .page-oauth-authorization-content__error(v-if="error")
            ui-icon.c-red(name="alert" left)
            .t-normal {{ error }}
          .page-oauth-authorization-content__loader(v-if="!error")
            ui-loader.mt-2(preloader text="Завершаем процесс авторизации...")
      .page-oauth-authorization__footer
        ui-btn(
          v-if="error"
          large
          primary
          :href="$path.signIn()"
          width="100%"
        ) Вернуться на страницу авторизации
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import TitledImage from '@/components/TitledImage.vue';

  import { UiLoader, UiIcon, UiBtn } from '@/uikit';
  import PortalDaLogo from '@/components/PortalDaLogo';
  import { mapActions } from 'vuex';
  import { service as API } from '@/services/auth';

  import { createLogger } from '@/uikit/util';
  const logger = createLogger('OAuthPage', 'blue');

  export default {
    name: 'page-oauth-authorize',
    components: { AppLayout, PortalDaLogo, UiBtn, UiLoader, UiIcon, TitledImage },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        // TODO: хранить в сессии prevPath?
        vm.prevPath = from.path;
      });
    },

    props: {
      provider: { type: String, default: undefined },
    },

    data() {
      return {
        error: undefined,
        prevPath: undefined,
        state: undefined,
        code: undefined,
        loading: true,
      };
    },

    mounted() {
      this.authorize();
    },

    methods: {
      async authorize() {
        try {
          const prevState = localStorage.getItem('oauthState');
          const prevCode = localStorage.getItem('oauthCode');
          const state = this.$route.query.state;
          const code = this.$route.query.code;

          // защищаемся от повторных запросов на авторизацию
          if (prevState && prevCode && prevState === state && prevCode === code) {
            this.$router.push(this.$path.home());
            return;
          }

          // UTM метки
          let utms = {};
          if (localStorage.getItem('utms')) {
            utms = JSON.parse(localStorage.getItem('utms'));
          }

          let callbackUrl = '';
          if (this.provider && this.provider.length) {
            callbackUrl = `${process.env.VUE_APP_HOST}/oauth/${this.provider}/callback`;
          }

          const { token, refreshToken, user } = await API.oauthAuthorize(
            this.provider,
            state,
            code,
            callbackUrl,
            utms
          );

          this.signInWithToken({ token, refreshToken, user });

          localStorage.setItem('oauthState', state);
          localStorage.setItem('oauthCode', code);

          // TODO: редиректить на prevPath или "на главную"
          // если пришел с датаРума - получаем ссылку для редиректа обратно в датарум
          if (localStorage.getItem('redirectToDataRoom') && user) {
            const data = await API.getUriToDataRoom();
            if (data && data.redirect_uri) {
              const redirectPath = data.redirect_uri;
              window.location.href = redirectPath;
              }
          } else {
            this.$router.push(this.$path.home());
          }
        } catch (error) {
          if (error.data && error.data.message) {
            this.error = error.data.message;
          } else {
            this.error = 'Невозможно осуществить вход в систему';
          }
          logger.error(error);
        }
      },

      ...mapActions('user', ['signInWithToken']),
    },
  };
</script>

<style lang="scss">
  .page-oauth-authorization {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    height: 100%;
    width: 100%;
    z-index: 6;

    .page-oauth-authorization__toolbar {
      display: flex;
      justify-content: center;
      flex-shrink: 1;
    }

    .page-oauth-authorization__content {
      display: flex;
      flex-grow: 1;
      align-items: center;

      .page-oauth-authorization-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 240px;

        .page-oauth-authorization-content__figure {
          flex-grow: 1;

          .figure__title {
            margin-top: 0;
          }
        }

        .page-oauth-authorization-content__error {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          max-width: 70%;

          .icon {
            flex-shrink: 0;
            flex-grow: 0;
          }
        }

        .page-oauth-authorization-content__loader {
          justify-self: end;
        }
      }
    }

    .page-oauth-authorization__footer {
      display: flex;
      flex-shrink: 1;
      min-height: 44px;
    }
  }
</style>
